import React from 'react'
import SbEditable from 'storyblok-react'
import { Box } from 'reflexbox'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'

import * as styles from './Highlight.module.scss'
import { Props } from './types'

const Highlight = ({ blok }: Props): JSX.Element => {
  const { image, label, title, text, footer, link } = blok

  return (
    <SbEditable content={blok}>
      <Box className={styles.content}>
        {image && image.filename ? (
          <Box className={styles.thumbnail} marginBottom={[4]}>
            <Image src={image.filename} />
            {label && (
              <Box paddingX={[4]} paddingY={[3]} className={styles.labelImage}>
                <Box as="span">{label}</Box>
              </Box>
            )}
          </Box>
        ) : label ? (
          <Box marginBottom={[2]} className={styles.label}>
            <Box as="span">{label}</Box>
          </Box>
        ) : null}
        {title && (
          <Box marginBottom={[image && image.filename ? 2 : 4]} as="h3">
            {title}
          </Box>
        )}
        {text && (
          <Box marginBottom={[2, null, null, 4]} className={styles.text}>
            <Markdown>{text}</Markdown>
          </Box>
        )}
        {footer && <Box className={styles.footer}>{footer}</Box>}
      </Box>
    </SbEditable>
  )
}

export default Highlight

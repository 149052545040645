import React from 'react'
import SbEditable from 'storyblok-react'
import { Flex, Box } from 'reflexbox'
import { Container } from 'src/components/UI/Grid/Grid'
import Image from 'src/components/UI/Image/Image'
import { Logotype } from 'src/components/UI/Logotype/Logotype'
import Components from 'src/storyblok/Components'

import { Props } from './types'
import * as styles from './PageHome.module.scss'

const PageHome = ({ blok }: Props): JSX.Element => {
  const { logotype, tagline, title, text, content } = blok || {}

  return (
    <SbEditable content={blok}>
      <Container paddingY={[10, null, null, '6vw']}>
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Box width={[1, null, null, '60vw']}>
            {logotype && logotype.filename ? (
              <Image src={logotype.filename} />
            ) : (
              <Logotype className={styles.logotype} />
            )}
          </Box>
        </Flex>
      </Container>

      <Box className={styles.content}>
        {content &&
          content.length > 0 &&
          content.map((blok: any) => {
            return React.createElement(Components(blok.component), {
              blok: blok,
              key: blok._uid,
            })
          })}
      </Box>
    </SbEditable>
  )
}

export default PageHome

import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { Container } from 'src/components/UI/Grid/Grid'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './TitleAndText.module.scss'
import { Props } from './types'

const TitleAndText = ({ blok, isFirst }: Props): JSX.Element => {
  const { title, text } = blok

  return (
    <SbEditable content={blok}>
      <Container
        bg="white"
        paddingY={[10, null, null, '4vw']}
        marginBottom={[10, null, null, '6vw']}
      >
        <Flex justifyContent={['center', null, null, 'center']}>
          <Box width={[1, null, null, null, 1, '60vw']}>
            {title && (
              <Box
                as={isFirst ? 'h1' : 'h2'}
                className={styles.display}
                marginBottom={[4, null, null, 4]}
                textAlign="center"
              >
                {title}
              </Box>
            )}
            {text && (
              <Box className={styles.paragraphs}>
                <Markdown>{text}</Markdown>
              </Box>
            )}
          </Box>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default TitleAndText

import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'

import * as styles from './Hero.module.scss'
import { Props } from './types'

const Hero = ({ blok }: Props): JSX.Element => {
  const { image } = blok

  return (
    <SbEditable content={blok}>
      <Container paddingY={[10, null, null, '6vw']}>
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Box width={[1, null, null, '60vw']}>
            {image && image.filename && <Image src={image.filename} />}
          </Box>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default Hero

import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import Markdown from 'src/components/Markdown/Markdown'

import { Props } from './types'

const Highlights = ({ blok }: Props): JSX.Element => {
  const { question, answer } = blok

  return (
    <SbEditable content={blok}>
      <Box>
        <Box as="h4">{question && question}</Box>
        <Box>{answer && <Markdown>{answer}</Markdown>}</Box>
      </Box>
    </SbEditable>
  )
}

export default Highlights

import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'

import * as styles from './ImageAndText.module.scss'
import { Props } from './types'

const ImageAndText = ({ blok }: Props): JSX.Element => {
  const { title, image, text } = blok

  return (
    <SbEditable content={blok}>
      <Container marginBottom={[10, null, null, '6vw']}>
        <Flex justifyContent={['center', null, null, 'center']}>
          <Box width={[1, null, null, null, 1, '60vw']}>
            {title && (
              <Box
                as="h2"
                className={styles.title}
                textAlign={['left', null, null, 'center']}
                marginBottom={
                  (image && image.filename) || text ? [2, null, null, 4] : 0
                }
              >
                {title}
              </Box>
            )}
          </Box>
        </Flex>
        <Row>
          <Column width={[1, null, null, text ? 6 / 12 : 12 / 12]}>
            {image && image.filename && <Image src={image.filename} />}
          </Column>
          <Column width={[1, null, null, 6 / 12]}>
            {text && (
              <Box className={styles.paragraphs}>
                <Markdown>{text}</Markdown>
              </Box>
            )}
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default ImageAndText

import React from 'react'
import SbEditable from 'storyblok-react'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import Components from 'src/storyblok/Components'
import { Flex, Box } from 'reflexbox'

import * as styles from './Highlights.module.scss'
import { Props } from './types'

const Highlights = ({ blok }: Props): JSX.Element => {
  const { title, items } = blok

  return (
    <SbEditable content={blok}>
      <Container
        paddingBottom={[5, null, null, '2vw']}
        // paddingBottom={title ? [10, null, null, '4vw'] : [5, null, null, '2vw']}
      >
        <Flex justifyContent={['center', null, null, 'center']}>
          <Box width={1}>
            {title && (
              <Box
                as="h2"
                className={styles.title}
                marginBottom={[3, null, null, 5]}
              >
                {title}
              </Box>
            )}
          </Box>
        </Flex>
        <Row>
          {items &&
            items.length > 0 &&
            items.map((blok: any) => {
              return (
                <Column
                  key={blok._uid}
                  width={[1, null, null, 6 / 12, 4 / 12]}
                  marginBottom={[4, null, null, 10]}
                >
                  <Box padding={[4]} bg="white" height="100%">
                    {React.createElement(Components(blok.component), {
                      blok: blok,
                    })}
                  </Box>
                </Column>
              )
            })}
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Highlights

import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { Container } from 'src/components/UI/Grid/Grid'

import * as styles from './Heading.module.scss'
import { Props } from './types'

const Heading = ({ blok }: Props): JSX.Element => {
  const { title } = blok

  return (
    <SbEditable content={blok}>
      <Container marginBottom={[2, null, null, 4]}>
        <Flex justifyContent={['center', null, null, 'center']}>
          <Box width={[1, null, null, null, 1, '60vw']}>
            {title && (
              <Box
                as="h2"
                className={styles.title}
                textAlign={['left', null, null, 'center']}
              >
                {title}
              </Box>
            )}
          </Box>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default Heading

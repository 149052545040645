import React from 'react'
import SbEditable from 'storyblok-react'
import { Container } from 'src/components/UI/Grid/Grid'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './Banner.module.scss'
import { Props } from './types'

const Banner = ({ blok }: Props): JSX.Element => {
  const { text } = blok

  return (
    <SbEditable content={blok}>
      <Container
        className={styles.banner}
        textAlign={['left', null, null, 'center']}
        paddingY={[4, null, null, '4vw']}
        marginBottom={[10, null, null, '4vw']}
        bg="white"
      >
        {text && <Markdown>{text}</Markdown>}
      </Container>
    </SbEditable>
  )
}

export default Banner

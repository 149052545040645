// Pages
import Page from './Pages/Page/Page'
import PageHome from './Pages/PageHome/PageHome'

// Global Modules
import Header from './GlobalModules/Header/Header'
import Footer from './GlobalModules/Footer/Footer'

// Modules
import TitleAndText from './Modules/TitleAndText/TitleAndText'
import Banner from './Modules/Banner/Banner'
import Highlights from './Modules/Highlights/Highlights'
import Faq from './Modules/Faq/Faq'
import ImageAndText from './Modules/ImageAndText/ImageAndText'
import Hero from './Modules/Hero/Hero'
import Heading from './Modules/Heading/Heading'

// Components
import Link from './Components/Link/Link'
import Text from './Components/Text/Text'
import Highlight from './Components/Highlight/Highlight'
import FaqItem from './Components/FaqItem/FaqItem'

// Not found
import ComponentNotFound from './ComponentNotFound'

// Modules
const ComponentList: any = {
  // Pages
  page: Page,
  page_home: PageHome,

  // Global Modules
  header: Header,
  footer: Footer,

  // Modules
  title_and_text: TitleAndText,
  banner: Banner,
  highlights: Highlights,
  faq: Faq,
  image_and_text: ImageAndText,
  hero: Hero,
  heading: Heading,

  // Components
  link: Link,
  text: Text,
  highlight: Highlight,
  faq_item: FaqItem,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }

  return ComponentList[type]
}

export default Components

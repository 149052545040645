import React from 'react'
import SbEditable from 'storyblok-react'
import { Box } from 'reflexbox'
import Components from 'src/storyblok/Components'

import { Props } from './types'
import * as styles from './Page.module.scss'

const Page = ({ blok }: Props): JSX.Element => {
  const { content } = blok || {}

  return (
    <SbEditable content={blok}>
      <Box className={styles.content}>
        {content &&
          content.length > 0 &&
          content.map((blok: any, index: number) => {
            return React.createElement(Components(blok.component), {
              blok: blok,
              key: blok._uid,
              isFirst: index === 0,
            })
          })}
      </Box>
    </SbEditable>
  )
}

export default Page
